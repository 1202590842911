import { isDefined } from '../../../utils/ts-utils';
import { Identity, ReactedIdentityInternal } from './post-reaction-types';
import {
  Reaction,
  ReactionCodeValue,
  isValidReactionCode,
} from '@wix/comments-ooi-client/reaction-types';

export const mainReactionCode = ReactionCodeValue.LIKE;

export const fromServerReaction = (
  reaction: Partial<ReactedIdentityInternal>,
): Reaction | undefined => {
  if (!reaction?.reactionCode) {
    return undefined;
  }

  if (!isValidReactionCode(reaction.reactionCode)) {
    return undefined;
  }

  return {
    total: reaction?.total ?? reaction?.identities?.length ?? 0,
    hasReacted: reaction?.hasReacted ?? false,
    reactionCode: reaction.reactionCode,
    identities: reaction?.identities?.map(fromServerIdentity).filter(isDefined) ?? [],
  };
};

// TODO: reactions - add server identity type
export const fromServerIdentity = (identity: any | undefined): Identity | undefined => {
  return !identity?.identityId || !identity?.identityType
    ? undefined
    : { identityId: identity.identityId, identityType: identity.identityType };
};
