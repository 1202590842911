import { createAction } from '@reduxjs/toolkit';
import { getReactionsState } from './post-reactions-selectors';
import { AppThunk } from '../../types/store-types';
import {
  Identity,
  ReactedIdentityInternal,
  ReactionsInternalState,
  RecentReactionInternal,
} from './post-reaction-types';
import { unreachable } from '../../../utils/ts-utils';
import { ReactionCode } from '@wix/comments-ooi-client/reaction-types';
import {
  getCurrentUser,
  getCurrentUserIdentity,
} from '../../../common/store/members/members-selectors';
import { Member } from '../../../api/members/member.type';

export const REACT_TO_POST_REQUEST = 'post/REACT_TO_POST_REQUEST' as const;
export const REACT_TO_POST_SUCCESS = 'post/REACT_TO_POST_SUCCESS' as const;
export const REACT_TO_POST_FAILURE = 'post/REACT_TO_POST_FAILURE' as const;

export type ReactToPostActions =
  | ReturnType<typeof reactToPostRequest>
  | ReturnType<typeof reactToPostSuccess>
  | ReturnType<typeof reactToPostFailure>;

export const reactToPostRequest = createAction(
  REACT_TO_POST_REQUEST,
  ({
    reactionCode,
    postId,
    subaction,
  }: {
    reactionCode: ReactionCode;
    postId: string;
    subaction: 'ADD' | 'REMOVE' | 'REPLACE';
  }) => ({
    payload: { _id: postId, reactionCode, subaction },
  }),
);

export const reactToPostSuccess = createAction(
  REACT_TO_POST_SUCCESS,
  ({
    reactedIdentities,
    recentReactions,
    postId,
  }: {
    reactedIdentities: ReactedIdentityInternal[];
    recentReactions: RecentReactionInternal[];
    postId: string;
  }) => ({
    payload: { reactedIdentities, recentReactions, _id: postId },
  }),
);

export const reactToPostFailure = createAction(
  REACT_TO_POST_FAILURE,
  ({ postId, error }: { postId: string; error: object }) => ({
    payload: { _id: postId, error },
  }),
);

export function reactToPost({
  postId,
  reactionCode,
}: {
  postId: string;
  reactionCode: ReactionCode;
}): AppThunk {
  return async (dispatch, getState, { request }) => {
    const state = getState();
    const currentUser = getCurrentUser(state);
    const identity = getCurrentUserIdentity(state) as any;
    const reactionsState = getReactionsState(state, postId);

    if (reactionsState.type === 'PENDING') {
      return; // We skip all clicks if reaction is pending
    }

    const subaction = getSubaction({
      reactionCode,
      state: reactionsState,
      identity,
    });

    dispatch(reactToPostRequest({ reactionCode, postId, subaction }));

    const promise =
      subaction === 'REMOVE'
        ? request.delete(`/platformized/v1/posts/${postId}/react`, {
            body: JSON.stringify({ reactionCode }),
            data: { reactionCode },
          })
        : request.put(`/platformized/v1/posts/${postId}/react`, { reactionCode });

    const reactedIdentities = updateReactedIdentities({
      subaction,
      reactionCode,
      reactionsState,
      identity,
    });

    const recentReactions = updateRecentReactions({
      subaction,
      reactionCode,
      reactionsState,
      currentMember: currentUser!,
    });

    return promise
      .then(
        () => dispatch(reactToPostSuccess({ reactedIdentities, recentReactions, postId })),
        (e) => dispatch(reactToPostFailure({ postId, error: e })),
      )
      .then(() => promise);
  };
}

const getActiveReactionCode = (state: ReactionsInternalState, identity: Identity) =>
  state.reactedIdentities.find((r) =>
    r.identities.find((i) => i.identityId === identity.identityId),
  )?.reactionCode;

const getSubaction = ({
  reactionCode,
  state,
  identity,
}: {
  reactionCode: ReactionCode;
  state: ReactionsInternalState;
  identity: Identity;
}) => {
  const activeReaction = getActiveReactionCode(state, identity);

  if (!activeReaction) {
    return 'ADD';
  }

  if (activeReaction === reactionCode) {
    return 'REMOVE';
  }

  return 'REPLACE';
};

const updateReactedIdentities = ({
  subaction,
  reactionCode,
  reactionsState,
  identity,
}: {
  reactionsState: ReactionsInternalState;
  reactionCode: ReactionCode;
  subaction: 'ADD' | 'REPLACE' | 'REMOVE';
  identity: Identity;
}): ReactedIdentityInternal[] => {
  switch (subaction) {
    case 'ADD':
      return addReaction({
        existingReactions: reactionsState.reactedIdentities,
        reactionToAdd: { reactionCode, identity },
      });
    case 'REPLACE':
      const reactionsList = removeReaction({
        existingReactions: reactionsState.reactedIdentities,
        reactionToRemove: {
          reactionCode: getActiveReactionCode(reactionsState, identity)!,
          identity,
        },
      });
      return addReaction({
        existingReactions: reactionsList,
        reactionToAdd: { reactionCode, identity },
      });
    case 'REMOVE':
      return removeReaction({
        existingReactions: reactionsState.reactedIdentities,
        reactionToRemove: {
          reactionCode: getActiveReactionCode(reactionsState, identity)!,
          identity,
        },
      });
    default:
      throw unreachable(subaction);
  }
};

export const updateRecentReactions = ({
  subaction,
  reactionsState,
  reactionCode,
  currentMember,
}: {
  reactionsState: ReactionsInternalState;
  subaction: 'ADD' | 'REMOVE' | 'REPLACE';
  reactionCode: ReactionCode;
  currentMember: Member;
}): RecentReactionInternal[] => {
  switch (subaction) {
    case 'ADD':
      const identityType = 'MEMBER' as any;
      return [
        ...reactionsState.recentReactions,
        {
          reactionCode,
          member: currentMember,
          identityId: currentMember.siteMemberId,
          identityType,
        },
      ];
    case 'REMOVE':
      return reactionsState.recentReactions.filter(
        (r) => r.identityId !== currentMember.siteMemberId,
      );
    case 'REPLACE':
      const currentReaction = reactionsState.recentReactions.find(
        (r) => r.identityId !== currentMember.siteMemberId,
      );

      return [...reactionsState.recentReactions, { ...currentReaction!, reactionCode }];
    default:
      throw unreachable(subaction);
  }
};

export const addReaction = ({
  existingReactions,
  reactionToAdd,
}: {
  existingReactions: ReactedIdentityInternal[];
  reactionToAdd: { reactionCode: ReactionCode; identity: Identity };
}): ReactedIdentityInternal[] => {
  const shouldCreateNewReactionsList = existingReactions.find(
    (r) => r.reactionCode === reactionToAdd.reactionCode,
  );

  if (!shouldCreateNewReactionsList) {
    return [
      ...existingReactions,
      {
        reactionCode: reactionToAdd.reactionCode,
        hasReacted: true,
        total: 1,
        identities: [reactionToAdd.identity],
      },
    ];
  }

  return existingReactions.map((r) => {
    if (r.reactionCode !== reactionToAdd.reactionCode) {
      return r;
    }

    const reaction = {
      ...r,
      hasReacted: true,
      total: r.total + 1,
      identities: [...r.identities, reactionToAdd.identity],
    };

    return reaction;
  });
};

export const removeReaction = ({
  existingReactions,
  reactionToRemove,
}: {
  existingReactions: ReactedIdentityInternal[];
  reactionToRemove: { reactionCode: ReactionCode; identity: Identity };
}): ReactedIdentityInternal[] => {
  const reactionExist = existingReactions.find(
    (r) => r.reactionCode === reactionToRemove.reactionCode,
  );

  if (reactionExist!.total === 1) {
    return existingReactions.filter((r) => r.reactionCode !== reactionToRemove.reactionCode);
  }

  return existingReactions.map((r) => {
    if (r.reactionCode !== reactionToRemove.reactionCode) {
      return r;
    }

    const reaction = {
      ...r,
      total: r.total - 1,
      hasReacted: false,
      identities: r.identities.filter((i) => i.identityId !== reactionToRemove.identity.identityId),
    };

    return reaction;
  });
};
